// src/pages/NotFound.scss

.notfound-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f9fafb;
    text-align: center;
  
    .error-code {
      font-size: 10rem;
      font-weight: 800;
      color: #E8001C;
      animation: bounce 1s infinite alternate;
    }
  
    .error-message {
      font-size: 2rem;
      font-weight: 600;
      color: #444;
    }
  
    .error-description {
      font-size: 1rem;
      color: #666;
      margin-bottom: 20px;
    }
  
    .error-image {
      margin: 20px 0;
      
      img {
        width: 250px;
        max-width: 100%;
      }
    }
  
    .back-home-btn {
      display: inline-block;
      padding: 12px 24px;
      font-size: 1.2rem;
      font-weight: bold;
      color: white;
      background-color: #007bff;
      border-radius: 8px;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
  
      &:hover {
        background-color: #0056b3;
        transform: scale(1.05);
      }
    }
  }
  
  // Keyframes for bounce effect
  @keyframes bounce {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-10px);
    }
  }
  