// primary btn
//E8001C
$btn_primary_color: #E8001C;
$btn_primary_hover_color: #ffffff;
$btn_intake_form: #5eaf61;

// secondary btn
$btn_secondary_color: #ffffff;
$btn_secondary_hover_color: #E8001C;

// light btn
$btn_light_color: #E8001C;
$btn_light_background_color: #F6F8FC;
$btn_light_border_color: #B6C8D6;
$btn_light_hover_color: #ffffff;

// link btn
$btn-link_color: #E8001C;
$btn-link_hover_color: #0E3B59;

.button,
button,
.btn {
  padding: 14px;
  font-weight: 700;
  font-size: $font_medium;
  line-height: 20px;
  min-width: 150px;
  border-radius: 25px;
  color: $bodycolor;
  text-transform: capitalize;
}

.primary_btn {
  display: flex;
  flex-wrap: wrap;
  button,
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    .mat-icon {
      margin-right: 10px;
    }
    &:last-child{
        margin-left: 15px;
    }
  }
  .btn.btn-primary {
    background-color: $btn_primary_color;
    border: 1px solid $btn_primary_color;
    color: $btn_primary_hover_color;
    margin: 0 0 0 15px;
    &:first-child{
      margin: 0; 
    }
    .mat-icon {
      height: 26px;
      width: 26px;
      min-width: 26px;
      border-radius: 50px;
      color: $btn_primary_color;
      background-color: $btn_primary_hover_color;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
    .btn_svg {
       border-radius: 50px;
       background-color: $btn_primary_hover_color;
       display: flex;
       align-items: center;
       justify-content: center;
       height: 26px;
       width: 26px;
       margin-right: 10px;
       svg {
        fill:$btn_primary_color;
       }
    }
    &:hover {
      background-color: $btn_primary_hover_color;
      border: 1px solid $btn_primary_color;
      color: $btn_primary_color;
      .mat-icon {
        background: white;
        color: $btn_primary_hover_color;
        background-color: $btn_primary_color;
      }
      a {
        border-radius: 50px;
        background-color: $btn_primary_color;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 26px;
        width: 26px;
        svg {
         fill:$btn_primary_hover_color;
        }
     }
    }
  }
  .btn-secondary{
    background-color: $btn_primary_hover_color;
      border: 1px solid $btn_primary_color;
      color: $btn_primary_color;
      &:hover{
        background-color: $btn_primary_color;
        border: 1px solid $btn_primary_color;
        color: $btn_primary_hover_color;
      }
  }
  .btn-light{
    background-color: $btn_light_background_color;
      border: 1px solid $btn_light_border_color;
      color: $btn_light_color;
      &:hover{
        background-color: $btn_light_color;
        border: 1px solid $btn_light_color;
        color: $btn_light_hover_color;
      }
  }
  .btn-link{
      color: $btn_link_color;
      text-decoration: none;
      &:hover{
        color: $btn_link_hover_color;
      }
  }

  .btn-transparent {
    background: #FFFFFF;
    border: 1px solid rgba(14, 59, 89, 0.20);
    transition: color .15s ease-in-out;
    &:hover{
      background-color: $btn_light_color;
      border: 1px solid $btn_light_color;
      color: $btn_light_hover_color;
      transition: color .15s ease-in-out;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: color .15s ease-in-out;
        svg {
         fill:$white_color;
         transition: color .15s ease-in-out;
        }
     }
    
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      transition: color .15s ease-in-out;
      svg {
        fill: rgba(14, 59, 89, 0.7);
        transition: color .15s ease-in-out;
      }
   }

  }

}

.btn.btn-primary{
  background-color: $btn_primary_color;
  border: 1px solid $btn_primary_color;
  color: $btn_primary_hover_color;
  &:hover{
    background-color: $btn_primary_hover_color;
    border: 1px solid $btn_primary_color;
    color: $btn_primary_color;
  }
}
.btn.btn-intake-form-button{
  background-color: $btn_intake_form;
  border: 1px solid $btn_primary_color;
  color: $btn_primary_hover_color;
  &:hover{
    background-color: $btn_primary_hover_color;
    border: 1px solid $btn_primary_color;
    color: $btn_primary_color;
  }
}
.closeEditBtn{
  display: flex;
  justify-content: right;
}
