@import '../../../src/assets/css/variable/variable';

footer{
    background: $footerbackground;
    .footer_top{
        padding: 50px 0 70px;
        .footer_item{
            margin:  15px 0;
            .footer_title{
                font-size: $font_large;
                font-weight: 600;
                color: $bodycolor;
                margin: 0 0 40px;
            }
            .footer_link{
                margin: 0 0 8px;
                line-height: 23px;
                color: $footerlinkcolor;
                font-size: $font_medium;
                font-weight: 400;
                label{
                    margin: 0 7px 0 0;
                }
                a{
                    color: $footerlinkcolor;
                    font-size: $font_medium;
                    font-weight: 400;
                    text-decoration: none;
                    &:hover{
                        color: $bodyhovercolor;
                    }
                }
            }
            &.footer_item1{
                .footer_item_inner{
                    .footer_logo{
                        margin: 0 0 15px;
                    }
                    .footer_desc{
                        font-size: $font_large;
                        font-weight: 400;
                        color: $footerlinkcolor;
                        line-height: 24px;
                    }
                }
            }
            &.footer_item3{
                .footer_link{
                    address{
                        display: inline-block;
                        margin: 0;
                    }
                }
            }
        }
    }
    .footer_bottom{
        text-align: center;
        padding: 18px 0;
        background: rgba(14, 59, 89, 0.1);
        .copyright{
            font-size: $font_medium;
        }
    }
}
@media screen and (min-width:$desktop_up) {
    footer .footer_top .footer_item.footer_item1 .footer_item_inner{
        padding: 0 40px;
    }
}
@media screen and (max-width:$large_phone) {
    footer .footer_top{
        padding: 30px 0;
    }
    footer .footer_top .footer_item .footer_title{
        margin: 0 0 20px;
    }
}