@import '../../../assets/css/variable/variable';
.intake_form{
   .tabs{
      .MuiTabPanel-root{
        padding: 0;
        .tabpanel_inner {
            padding: 25px 0;
            &.question{
                form{
                    .form_title{
                        font-weight: 600;
                        font-size: 20px;
                        word-break: break-word;
                        line-height: 24px;
                        color: #212C2D;
                        margin: 0 0 15px;
                    }
                    fieldset{
                        margin: 0 0 30px;
                        .form_group_content{
                            margin: 0 0 15px;
                            .MuiFormGroup-root{
                                .MuiFormControlLabel-label{
                                    font-family: $font_family_body,$font_sans_sarif;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: rgba(0, 0, 0, 0.8);
                                }
                            }
                        }  
                        .question_label{
                            margin: 0 0 30px;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 17px;
                            color: $black_color;
                        }
                    }
                }
             
            }
        }
      }
   }
}


@media screen and (max-width:$tablet) {
    .intake_form .main_block .top_bar .main_title {
        font-size: 22px;
        line-height: 26px;
    }
    .intake_form .main_block .top_bar .sub_title{
        font-size: 16px;
    }
    .intake_form .tabs .MuiTabPanel-root .tabpanel_inner .form_title{
        font-size: 22px;
        line-height: 26px;
    }
    .intake_form .tabs .MuiTabPanel-root .tabpanel_inner.history form fieldset .form_group_content .form_label{
        font-size: 16px;
    }
    .intake_form .tabs .MuiTabPanel-root .tabpanel_inner.history form fieldset .form_group_content {
        margin: 25px 0;
    }
}
@media screen and (max-width:$small_phone) {
    .intake_form .main_block .top_bar .sub_title {
        font-size: 14px;
    }
}

.p_wrap {
    white-space: pre-line;
  }


  @import '../../../assets/css/variable/variable';

.idverify{
    .main_block{
        .title_wrapper{
            margin: 0 0 70px;
            .title{
                font-size: 30px;
                font-weight: 600;
                line-height: 30px;
                color: #212C2D;
                margin: 0 0 8px;
            }
            .desc{
                font-size: $font_ex_large;
                font-weight: 400;
                line-height: 24px;
                color: #212C2D;
            }
        }
        .upload_doc_section{
            margin: 0 0 20px;
            .upload_item_wrapper{
                margin: 0 -26px;
                .upload_item{
                    padding: 0 26px;
                    margin: 0 0 30px;
                    .form_field_wrapper{
                        margin: 0 0 20px;
                        label{
                            font-weight: 500;
                            font-size: $font_medium;
                            line-height: 17px;
                            color: $black_color;
                        }
                        .upload_input {
                            position: relative;
                            .user_upload_content{
                                display: flex;
                                flex-direction: column;
                                min-height: 160px;
                                justify-content: center;
                                align-items: center;
                                background: rgba(201, 203, 205, 0.1);
                                border: 1px solid rgba(14, 59, 89, 0.2);
                                border-radius: 5px;
                                label{
                                    font-weight: 300;
                                    font-size: 14px;
                                    line-height: 17px;
                                    color: $black_color;
                                    margin: 7px 0 15px;
                                }
                                span{
                                    font-weight: 500;
                                    font-size: 18px;
                                    line-height: 22px;
                                    color: $successcolor;
                                    text-decoration: underline;
                                }
                            }
                            .user_upload_input{
                                width: 100%;
                                min-height: 160px;
                                opacity: 0;
                                position: absolute;
                                top: 0;
                                bottom: auto;
                            }
                        }
                    } 
                    .upload_doc_item_block{
                        .item_wrapper{
                            display: flex;
                            flex-wrap: wrap;
                            gap: 20px;
                            .item {
                                .item_inner{
                                    .image{
                                        position: relative;
                                        display: inline-block;
                                        vertical-align: top;
                                        img{
                                            width: 105px;
                                            height: 105px;
                                            line-height: 105px;
                                            border-radius: 5px;
                                            background: rgba(201, 203, 205, 0.1);
                                            border: 1px solid rgba(14, 59, 89, 0.2);
                                            object-fit: contain;
                                        }
                                        .btn_close{
                                            position: absolute;
                                            right: -10px;
                                            left: auto;
                                            top: -10px;
                                            bottom: auto;
                                            background-color: $bodyhovercolor;
                                            border-radius: 50%;
                                            width: 24px;
                                            height: 24px;
                                            text-align: center;
                                            cursor: pointer;
                                            svg{
                                                color: $white_color;
                                                font-size: 20px;
                                            }
                                        }
                                        
                                    }
                                }
                            }
                        }
                     
                    }
                }
            }
          

        }
        .privacy_policy{
            text-align: center;
            .custom_checkbox{
                text-align: left;
                font-size: $font_medium;
                color: $black_color;
                display: inline-block;
            }
            .primary_btn{
                justify-content: center;
                margin: 30px 0;
                .btn{
                    border-radius: 5px;
                    font-size: $font_ex_large;
                    font-weight: 600;
                    text-transform: uppercase;
                    min-width: 200px;
                    min-height: 78px;
                }
            }
            .hippa_img{
                margin: 0 0 20px;
            }
        }
    }
}

@media screen and (max-width:$medium_phone) {
    .idverify .main_block .title_wrapper .title{
        font-size: 20px;
        line-height: 24px;
    }
    .idverify .main_block .title_wrapper .desc{
        font-size: 16px;
    }
    .idverify .main_block .title_wrapper {
        margin: 0 0 40px;
    }
}
