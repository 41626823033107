
// colors
$bodycolor:#0E3B59;
$bodyhovercolor:#E8001C;
$bodybackground:#F0F1F2;
$bodyopacitycolor:rgba(14, 59, 89, 0.8);
$bodysecondarycolor:#E8001C;
$labelcolor:rgba(14, 59, 89, 0.8);
$formfiledbackgroundcolor:#ffffff;
$formfiledbordercolor: rgba(14, 59, 89, 0.2);
$formfiled_color:#0E3B59;
$white_color: #ffffff;
$graylight_color:rgba(128, 128, 128, 0.5);
$black_color:#000000;
$blacklight_color:rgba(0, 0 ,0 ,80%);
$linkcolor: #E8001C;
$linkhovercolor: #0E3B59;
$successcolor:#299D00;

$footerbackground:#f8f8f8;
$footerlinkcolor: #898989;

$btn_danger:#FBC3C3;
$btn_confirmed:#EBF5FB;




$sidebarcolor:#F6F8FC;
$common_border:#B6C8D6;

$star_color: #E8A908;
$homepage_hovercolor:#3DA7C8;





// Counter background
$bright_blue: rgba(11, 73, 119, 0.12);
$dark_blue: rgba(80, 81, 249, 0.2);
$light_red: rgba(255, 83, 83, 0.1);
$light_blue: #D9EFFF;
$light_green: #EBFBEE;
$light_orange: #FBF3EA;
$light_yellow: #FFF7E6;
$light_violet: #F0EAFF;
$light_skyblue: #ECF9FF;















