// src/pages/ErrorPage.scss

.error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    color: white;
  
    .error-content {
      background: linear-gradient(to right, #ff6b6b, #ffa07a);
      padding: 30px;
      border-radius: 12px;
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(10px);
      animation: fadeIn 1s ease-in-out;
    }
  
    .error-icon {
      font-size: 4rem;
      animation: shake 1s infinite alternate;
      margin: 50px 0px;
    }
  
    .error-title {
      font-size: 2.5rem;
      font-weight: bold;
      margin-top: 10px;
    }
  
    .error-description {
      font-size: 1.2rem;
      margin-top: 10px;
    }
    .back-home-btn {
        display: inline-block;
        padding: 12px 24px;
        font-size: 1.2rem;
        font-weight: bold;
        color: white;
        background-color: #007bff;
        border-radius: 8px;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
    
        &:hover {
          background-color: #0056b3;
          transform: scale(1.05);
        }
      }
  }
  
  // Keyframes for animations
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes shake {
    from {
      transform: rotate(-5deg);
    }
    to {
      transform: rotate(5deg);
    }
  }
  