@import '../../../assets/css/variable/variable';
.intake_form{
   .tabs{
      .MuiTabPanel-root{
        padding: 0;
        .tabpanel_inner {
            padding: 25px 0;
            .form_title{
                font-weight: 400;
                font-size: 35px;
                word-break: break-word;
                line-height: 35px;
                color: #212C2D;
                margin: 0 0 15px;
            }
            .form_sub_title{
                font-weight: 300;
                font-size: 16px;
                line-height: 19px;
                color: $black_color;
            }
            &.history{
                form{
                    fieldset{
                        margin: 0 0 30px;
                        .form_field_wrapper{
                            .field_info{
                                font-weight: 300;
                                font-size: $font_small;
                                line-height: 17px;
                                color: $black_color;
                                margin: 0 0 10px;
                            }
                        } 
                        .form_group_content{
                            margin: 35px 0;
                            .form_label{
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 24px;
                                color: #212C2D;
                                margin: 0 0 7px;
                            }
                            .form_sub_label{
                                font-weight: 300;
                                font-size: 16px;
                                line-height: 19px;
                                color: $black_color;
                                margin: 0 0 10px;
                            }
                            .MuiFormGroup-root{
                                .MuiFormControlLabel-label{
                                    font-family: $font_family_body,$font_sans_sarif;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: rgba(0, 0, 0, 0.8);
                                }
                            }
                            .other{
                                display: flex;
                                label{
                                    margin-right: 0;
                                }
                                .form_field_wrapper{
                                    margin: 0;
                                }
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }  
                    }
                }
             
            }
        }
      }
   }
}


@media screen and (max-width:$tablet) {
    .intake_form .main_block .top_bar .main_title {
        font-size: 22px;
        line-height: 26px;
    }
    .intake_form .main_block .top_bar .sub_title{
        font-size: 16px;
    }
    .intake_form .tabs .MuiTabPanel-root .tabpanel_inner .form_title{
        font-size: 22px;
        line-height: 26px;
    }
    .intake_form .tabs .MuiTabPanel-root .tabpanel_inner.history form fieldset .form_group_content .form_label{
        font-size: 16px;
    }
    .intake_form .tabs .MuiTabPanel-root .tabpanel_inner.history form fieldset .form_group_content {
        margin: 25px 0;
    }
}
@media screen and (max-width:$small_phone) {
    .intake_form .main_block .top_bar .sub_title {
        font-size: 14px;
    }
}

.line_wrap {
    white-space: pre-wrap;
  }

