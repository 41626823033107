@import '../../assets/css/variable/variable';

.report{
    .main_block{
        .main_title{
            color: $bodysecondarycolor;
            margin: 0 0 10px;
        }
        .report_id{
            font-weight: 600;
            font-size: 22px;
            line-height: 27px;
            margin: 0 0 25px;
        }
        .tabs{
            .MuiTabPanel-root{
                padding: 0;
                .tabpanel_inner {
                    .table_wrapper {
                        .table_inner {
                            .MuiTableContainer-root{
                                table {
                                    thead {
                                        .MuiTableRow-head{
                                            background: #ffffff;
                                            .MuiTableCell-root{
                                                color: #212C2D;
                                                font-weight: 600;
                                            }
                                        }
                                    }
                                    tbody {
                                        .MuiTableRow-root{
                                            &:nth-child(even){
                                                background-color: $white_color;
                                            }
                                            &:nth-child(odd){
                                                background-color: #F5F7F8;
                                            }
                                            .MuiBox-root {
                                                margin: 0;
                                                display: inline-block;
                                                width: 292px;
                                                .progress_slider_wrap{
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    .MuiSlider-root{
                                                        height: 37px;
                                                        border-radius: unset;
                                                        padding: 5px 0;
                                                        // color: rgba(255,101,80,1);
                                                        .MuiSlider-rail{
                                                            border: 1px solid #636466;
                                                            opacity: 1;
                                                            background: -moz-linear-gradient(270deg, #B43610 0%, #C6621D 3.57%, #DF9C2E 7.66%, #EEC63B 10.41%, #7FC45F 18.39%, #7FC45F 79.6%, #EEC13A 87.66%, #DD992D 91.95%, #BB4816 96.05%, #A71707 100%);
                                                            background: -webkit-linear-gradient(270deg, #B43610 0%, #C6621D 3.57%, #DF9C2E 7.66%, #EEC63B 10.41%, #7FC45F 18.39%, #7FC45F 79.6%, #EEC13A 87.66%, #DD992D 91.95%, #BB4816 96.05%, #A71707 100%);
                                                            background: linear-gradient(270deg, #B43610 0%, #C6621D 3.57%, #DF9C2E 7.66%, #EEC63B 10.41%, #7FC45F 18.39%, #7FC45F 79.6%, #EEC13A 87.66%, #DD992D 91.95%, #BB4816 96.05%, #A71707 100%);
                                                        }
                                                        .MuiSlider-track{
                                                            border-color: #636466;
                                                            border-width: 1px 0 1px 1px;
                                                            border-style: solid;
                                                            background:transparent;
                                                        }
                                                        .MuiSlider-thumb{
                                                            width: auto;
                                                            height: auto;
                                                            top: 10%;
                                                            border-radius: unset;
                                                            background-color:unset;
                                                            border-left: 8px solid transparent;
                                                            border-right: 8px solid transparent;
                                                            border-top: 12px solid $bodycolor;
                                                            .MuiSlider-valueLabel{
                                                                background-color: $bodycolor;
                                                                &:before{
                                                                    background-color:$bodycolor
                                                                }
                                                                &.Open{
                                                                    -webkit-transform: translateY(-150%) scale(1);
                                                                    -moz-transform: translateY(-150%) scale(1);
                                                                    -ms-transform: translateY(-150%) scale(1);
                                                                    transform: translateY(-150%) scale(1);
                                                                }
                                                            }
                                                            &:hover{
                                                                box-shadow: none;
                                                            }
                                                        }
                                                    }
                                                    p{
                                                        margin: 0 10px;
                                                        color: #212C2D;
                                                        font-size: $font_medium;
                                                        font-weight: 500;
                                                        line-height: 19px;
                                                        min-width: 52px;
                                                        font-family: $font_family_body,$font_sans_sarif;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .overview_desc{
                        margin: 25px 0 20px;
                    }
                    .tab_data{
                        margin-top: 25px;
                        .tab_title{
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 24px;
                            color: $black_color;
                            margin: 0 0 10px;
                        }
                        p{
                            span{
                                font-weight: 500;
                            }
                        }
                    }
                    &.graph{
                        .chart{
                            margin: 30px 0 70px;
                            text-align: center;
                            .chart_inner{
                                .chart_title{
                                    font-weight: 600;
                                    font-size: 30px;
                                    line-height: 36px;
                                    color: $bodycolor;
                                    margin: 0 0 30px;
                                }
                                .content{
                                    margin: 0 auto;
                                    width: 500px;
                                    height: 328px;
                                }
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                    &.reported_symptoms{
                        .table_wrapper {
                            &.patient_symptoms{
                                // border-right: 1px solid #dddddd;
                                tr{
                                    td{
                                        .result{
                                            display: flex;
                                            align-items: center;
                                            .MuiBox-root {
                                                margin: 0 15px;
                                                .MuiLinearProgress-root{
                                                    height: 10px;
                                                    border-radius: 10px;
                                                    background-color: rgba(0, 184, 76, 0.3);
                                                    .MuiLinearProgress-bar{
                                                        background-color: #00B84C;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.symptoms_checklist{
                                .table_inner {
                                    .MuiTableContainer-root{
                                        table {
                                            thead {
                                                .MuiTableRow-head{
                                                    background: rgba(14, 59, 89, 0.1);    
                                                }
                                            }
                                            tbody {
                                                .MuiTableRow-root{
                                                    .MuiTableCell-root{
                                                        &:first-child{
                                                            background-color: $white_color;
                                                            border-right: 1px solid #dddddd;
                                                            // &::after{
                                                            //     content: "";
                                                            //     width: 22px;
                                                            //     height: 46px;
                                                            //     background-color: rgba(14, 59, 89, 0.4);
                                                            //     display: block;
                                                            //     position: absolute;
                                                            //     right: -30px;
                                                            //     left: auto;
                                                            //     top: 50%;
                                                            //     bottom: auto;
                                                            //     transform: translateY(-50%);
                                                            // }
                                                                // &.active{
                                                                //     &::after{
                                                                //         width: 90px;
                                                                //         right: -95px;
                                                                //     }
                                                                // }
                                                        }
                                                        .box_content_wrapper{
                                                            display: flex;
                                                            .box_content{
                                                                width: 50%; 
                                                                height: 22px;
                                                                display: inline-block;
                                                                position: absolute;
                                                                top: 50%;
                                                                bottom: auto;
                                                                left: 0;
                                                                right: auto;
                                                                background-color: rgba(14, 59, 89, 0.4);
                                                                transform: translateY(-50%);
                                                                &.normal_box {      
                                                                    width: 5px;
                                                                    height: 22px;
                                                                    transform: translate(100%, -50%);
                                                                    right: -8px;
                                                                    left: auto;
                                                                }
                                                                &.mild_box{
                                                                    // background-color: #299D00;
                                                                    display: block;
                                                                }
                                                                &.moderate_box {
                                                                    // background-color: #FFA800;
                                                                    width: 80%;
                                                                    transform: translate(-37%, -50%);
                                                                    display: block;
                                                                }
                                                                &.severe_box  {
                                                                    // background-color: #FF4F64;
                                                                    width: 112%;
                                                                    transform: translate(-55%, -50%);
                                                                    display: block;
                                                                }     
                                                            }    
                                                        } 
                                                    }
                                                    &:nth-child(odd){
                                                        background-color: $white_color;
                                                        .MuiTableCell-root{
                                                            &:first-child{
                                                                background-color: $white_color;
                                                            }
                                                        }
                                                    }
                                                    &:nth-child(even){
                                                        background-color: $white_color;
                                                        .MuiTableCell-root{
                                                           &:first-child{
                                                                background-color: #F5F7F8;
                                                           } 
                                                        }
                                                    }
                                                    .MuiTableCell-root{
                                                        position: relative;
                                                        .tickmark {
                                                            display: inline-block;
                                                            border-left: 2px dashed #299D00;
                                                            height: 100%;
                                                            position: absolute;
                                                            top: 50%;
                                                            bottom: auto;
                                                            left: 50%;
                                                            transform: translate(-50%, -50%);
                                                            &.mild{
                                                                border-color: #299D00;
                                                            }
                                                            &.moderate{
                                                                border-color: #FFA800;
                                                            }
                                                            &.severe{
                                                                border-color: #FF4F64;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                        }
                                    }
                                }
                            }
                        } 
                        .result_date{
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 19px;
                            color: #212C2D;
                            padding: 16px;
                        }  
                    }
                    &.lab_comments{
                        .tab_data{
                            p{
                                line-height: 30px;
                                margin-bottom: 20px;
                            }
                        }
                    }
               
                }
            }
         
           
        }
    }
}
@media screen and (max-width:1920px) {
    .test_overview .table_inner{
        overflow-x: unset;
        overflow-y: unset;
        white-space: normal;
    }
}
@media screen and (max-width:$tablet) {
    .back_link .action_link{
        font-size: 22px;
        line-height: 26px;
    }
    .report .main_block .report_id{
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 15px;
    }
}
@media screen and (max-width:$large_phone) {
    .test_overview table,
    .test_overview tbody, 
    .test_overview th, 
    .test_overview td, 
    .test_overview tr {
        display: block;
        min-width: auto;
    }
    .test_overview thead{
        display: none;
    }
    .test_overview thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    .test_overview tbody tr {
        margin: 0 0 1rem 0;
    }
    .test_overview tbody .MuiTableCell-root {
        display: flex;
        flex-direction: column;
        padding: 10px 15px;
    }
    .report .main_block .tabs .MuiTabPanel-root .tabpanel_inner .table_wrapper .table_inner .MuiTableContainer-root table tbody .MuiTableRow-root .MuiBox-root{
        width: 100%;
    }
    .test_overview tbody .MuiTableCell-root:before {
        margin-bottom: 5px;
        font-size: $font_medium;
        font-weight: 600;
        color: #212C2D;
    }
    .test_overview tbody .MuiTableCell-root:nth-child(1):before { content: "Test Name"; }
    .test_overview tbody .MuiTableCell-root:nth-child(2):before { content: "Results"; }
    .test_overview tbody .MuiTableCell-root:nth-child(3):before { content: "Range"; }

    // Reported symptom tabs reported_symptoms css
    .patient_symptoms tbody .MuiTableCell-root:nth-child(1):before { content: "Symptom Categories"; }
    .patient_symptoms tbody .MuiTableCell-root:nth-child(2):before { content: "Results"; }









    
    // Reported symptom tabs symptoms_checklist css
    .symptoms_checklist.table_wrapper table{
        display: table;
        min-width: 650px;
    }
    .symptoms_checklist.table_wrapper tr{
        display: table-row;
    }
    .symptoms_checklist.table_wrapper th{
        display: table-cell;
    }
    .symptoms_checklist.table_wrapper td{
        display: table-cell;
    }
    .symptoms_checklist.table_wrapper .MuiTableRow-root .MuiTableCell-root{
        padding: 16px;
        display: table-cell;
    }
    .symptoms_checklist.table_wrapper tbody{
        display: table-row-group;
    }
    .symptoms_checklist.table_wrapper thead{
        display: table-header-group;
    }
    .symptoms_checklist.table_wrapper thead tr {
        position: static;
        display: table-row;
    }
    .symptoms_checklist.table_wrapper .table_inner .MuiTableContainer-root table tbody .MuiTableRow-root .MuiTableCell-root{
        white-space: nowrap;
        word-wrap: normal;
    }
    .symptoms_checklist.table_wrapper tbody tr {
        margin: 0;
    }
    .symptoms_checklist.table_wrapper tbody .MuiTableCell-root {
        display: block;
        padding: 0;
    }
    .symptoms_checklist.table_wrapper tbody .MuiTableCell-root:before {
      display: none;
    }
}
@media screen and (max-width:$medium_iphone) {
    .report .main_block .tabs .MuiTabPanel-root .tabpanel_inner.graph .chart .chart_inner .content{
        width: 100%;
        height: 250px;

    }
}
@media screen and (max-width:$medium_phone) {
    .report .main_block .tabs .MuiTabPanel-root .tabpanel_inner.graph .chart .chart_inner .chart_title{
        font-size: 22px;
        line-height: 24px;
        margin: 0 0 20px;
    }
}