@import '../../assets/css/variable/variable';
.intake_form{
    .main_block{
        .top_bar{
            flex-direction: column;
            align-items: flex-start;
            .main_title{
                font-size: 30px;
                line-height: 36px;
            }
            .sub_title{
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #212C2D;
                text-transform: uppercase;
                margin-top: 5px;
            }
        }
        .bottom_bar{
            .tabs{
                .MuiTabs-root{
                    border-bottom: 1px dashed rgba(14, 59, 89, 0.25);
                    button{
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: rgba(33, 44, 45, 0.47);
                        border-radius: unset;
                        align-items: flex-start;
                        font-family: $font_family_body,$font_sans_sarif;
                        &.Mui-selected{
                            color: $black_color;
                            background-color: $white_color;
                        }
                        &:first-child{
                            padding-left: 0;
                            padding-right: 32px;
                        }
                    }
                    .MuiTabs-indicator{
                        background-color: $bodysecondarycolor;
                        transition: none;
                        display: none;
                    }
                }
            }
        }

    }
}

@media screen and (max-width:$large_phone) {
    .intake_form .main_block .bottom_bar .tabs .MuiTabs-root button:first-child{
        padding: 12px 16px;
    }
}