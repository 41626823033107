@import '../../assets/css/variable/variable';

.consultation_homepage{
    background-color: $white_color;
    padding: 40px 0 70px;
    border-top: 1px solid #F4F4F4;
    .consultation_block{
        margin: 0 0 15px;
        .main_title{
            font-size: 28px;
            font-weight: 500;
            line-height: 38px;
            color: $bodycolor;
            margin: 0 0 30px;
        }
        .consult_item{
            margin: 0 0 30px;
            padding-top: 24px;
            .consult_item_inner{
                display: flex;
                align-items: center;
                .consult_desc{
                    margin: 0 0 0 25px;
                    .title{
                        font-size: 22px;
                        font-weight: 400;
                        line-height: 22px;
                        margin: 0 0 8px;
                        color: $bodycolor;
                    }
                    .desc{
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 20px;
                        color: #7A7289;
                    }
                }
            }
        }
    }
    .consult_login{
        .login_title{
            font-weight: 600;
            font-size: 36px;
            line-height: 44px;
            color: $bodycolor;
            margin: 0 0 35px;
            padding: 0 0 22px;
            position: relative;
            &:after{
                content: "";
                display: block;
                width: 37px;
                height: 4px;
                background: $bodysecondarycolor;
                border-radius: 3px;
                position: absolute;
                bottom: 0;
                top: auto;
                left: 0;
                right: auto;
            }
        }
        .linkto{
            color: $bodysecondarycolor;
            text-decoration: transparent;
            &:hover{
                text-decoration: underline;
            }
            &.signuplink{
                color: $bodycolor;
                text-decoration: none;
                .signup_txt{
                    color: $bodysecondarycolor;
                    font-weight: 600;
                    text-transform: uppercase;
                }
                &:hover{
                    .signup_txt{
                        color: $bodycolor;
                    }
                }
            }
            &.backtologin{
                color: $bodycolor;
                text-decoration: none;
                .login_txt{
                    color: $bodysecondarycolor;
                    font-weight: 600;
                    text-transform: uppercase;
                }
                &:hover{
                    .login_txt{
                        color: $bodycolor;
                    }
                }
            }
        }
        .password-input-wrapper {
            position: relative;
          }
          
          .password-input-wrapper svg {
            position: absolute;
            top: 50%;
            right: 10px; /* Adjust the right distance as needed */
            transform: translateY(-50%);
            cursor: pointer;
          }
        .signup_btn{
            margin: 25px 25px 25px 0;
        }
        padding-right: 153px;
        .login_title{
            font-size: 30px;
            font-weight: 800;
            line-height: 34px;
        }
        .consult_field_wrapper{
            display: flex;
            align-items: center;
            .form_field_wrapper{
                margin: 0;
                min-width: 387px;
            }
            .btn {
                margin: 0 0 0 15px;
            }
        }
    }
    .testimonial{
        margin-top: 25px;
       
        .testimonial_inner{
            border-radius: 15px;
            border: 1px solid #EDEDED;
            padding: 25px 25px 0px 25px ;
            width: 90%;
            margin: 0 auto;
            .item_wrapper {
                .testimonial_item{
                    .testimonial_item_inner{
                        text-align: center;   
                        max-width: 400px;
                        margin: auto;              
                            img{
                                max-width: 100%;
                                width: 81px;
                                height: 81px;
                                line-height: 81px;
                                border-radius: 50%;
                                margin: 0 auto 20px;
                            }
                            .testimonial_title{
                                margin: 0 0 20px;
                                .title{
                                    font-size: 26px;
                                    font-weight: 800;
                                    line-height: 26px;
                                    color: $black_color;
                                }
                                .sub_title{
                                    color: #555555;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 14px;
                                }
                            }
                            .testimonial_desc{
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 24px;
                                color: $black_color;
                                margin: 0 0 35px;
                            }
                            .testimonial_rating{
                                .MuiRating-root{
                                    color: #FF8A00;
                                }
                            }

                    }
                }
            }
            .owl-dots{
                position: absolute;
                left: 0;
                right: 0;
                bottom: -70px;
                top: auto;
                margin-top: 0;
                .owl-dot{
                    margin: 0 3px;
                    span{
                        width: 11px;
                        height: 11px;
                        line-height: 11px;
                    }
                }
            }
        }
    }
}



@media screen and (max-width:$desktop_medium) {
  
    .consultation_homepage .consultation_block .main_title{
        font-size: 41px;
        line-height: 50px;
    }
}
@media screen and (max-width:$desktop) {
   
    .consultation_homepage .consultation_block .main_title {
        font-size: 35px;
        line-height: 44px;
    }
    .consultation_homepage .testimonial .testimonial_inner{
        width: 100%;
    }
}
@media screen and (max-width:$tablet) {
    
    .consultation_homepage .consult_login{
        padding-right: 0px;
    }
    .consultation_homepage .consultation_block .main_title{
        font-size: 24px;
        line-height: 30px;
    }
    .consultation_homepage .consult_login .consult_field_wrapper .form_field_wrapper {
        min-width: auto;
        width: 100%;
    }
}
@media screen and (max-width:$large_phone) {
    .consultation_homepage .consult_login{
        padding-right: 0px;
    }
    .consultation_homepage .consultation_block {
        margin: 0;
    }
    .consultation_homepage .testimonial {
        margin-top: 50px;
    }
}
@media screen and (max-width:$medium_iphone) {
  
        .consultation_homepage .consult_login{
            padding-right: 0px;
        }
    .consultation_homepage .consult_login .login_title{
        font-size: 24px;
        line-height: 30px;
    }
}
@media screen and (max-width:$small_phone) {
    .consultation_homepage .consult_login{
        padding-right: 0px;
    }
    .consultation_homepage .consult_login .consult_field_wrapper{
        flex-direction: column;
    }
    .consultation_homepage .consult_login .consult_field_wrapper .btn {
        margin: 15px 0 0;
        width: 100%;
    }
}

