@import '../../../src/assets/css/variable/variable';
header{
    background-color: $white_color;
    padding: 20px 0;    
    .navbar {
        padding: 0;
            .logo{
                img{
                    width: 153px;
                    height: 45px;
                }
            }
            .navbar-collapse{
                .navbar-nav{
                    li{
                        margin: 0 15px;
                        .nav-link {
                            font-weight: 500;
                            font-size: $font_ex_large;
                            line-height: 24px;
                            color: $bodycolor;
                            &:hover,&:focus{
                                color: $bodysecondarycolor; 
                            }
                            &.active{
                                color: $bodysecondarycolor;
                                cursor: default;
                            }
                        }
                    }
                }
            }
            .profile{
                .profile_inner{
                    display: flex;
                    align-items: baseline;
                    justify-content: center;
                    gap: 0 10px;
                    max-width: 150px;
                    margin: 0 0 0  15px;
                    cursor: pointer;
                    .profile_img{
                        img{
                            width: 32px;
                            min-width: 32px;
                            height: 32px;
                            line-height: 32px;
                            border-radius: 50%;
                        }
                    }
                    .profile_desc{
                        text-transform: capitalize;
                        .profile_name{
                            font-size: $font_small;
                            font-weight: 500;
                            color: $bodycolor;
                            line-height: 16px;
                            margin: 0 0 5px;
                        }
                        .profile_desc{
                            font-size: $font_extra_small;
                            font-weight: 400;
                            color: #898989;
                            line-height: 14px;
                        }
                    }
                    &:after{
                        color: #8197A6;
                        border-top: 0.4em solid;
                        border-right: 0.4em solid transparent;
                        border-left: 0.4em solid transparent;
                    }
                    &.show{
                        &:after{
                            transform: rotateX(180deg);
                        } 
                    }
                }
                ul{
                    margin-top: 24px;
                    right: 0;
                    left: auto;
                }
             
                
            }
       
    }
    &.sticky{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
        background-color: $white_color;
        animation: 0.8s ease 0s normal both 1 running fixedAnim;
		-webkit-animation: 0.8s ease 0s normal both 1 running fixedAnim;
		-moz-animation: 0.8s ease 0s normal both 1 running fixedAnim;
		-ms-animation: 0.8s ease 0s normal both 1 running fixedAnim;
		-o-animation: 0.8s ease 0s normal both 1 running fixedAnim;
    }
    @-webkit-keyframes fixedAnim{0%{top:-100%;}100%{top:0;}}
	@-moz-keyframes fixedAnim{0%{top:-100%;}100%{top:0;}}
	@keyframes fixedAnim{0%{top:-100%;}100%{top:0;}}
}


@media screen and (min-width:$desktop_pro_up) {
    header .navbar .container .navbar-collapse{
       justify-content: flex-end;
    }
}
@media screen and (max-width:$tablet) {
    header .navbar .container{
        width: 100%;
    }
    header .navbar .navbar-toggler{
        min-width: auto;
        padding: 0;
        border: none;
    }
    header .navbar .navbar-toggler:focus{
        box-shadow: none;
    }
    header .navbar .navbar-toggler{
        order:1;
    }
    header .navbar .logo{
        order: 2;
        flex-grow: 1;
        margin: 0 15px;
    }
    header .navbar .profile{
        order:3;
    }
    header .navbar .navbar-collapse{
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin: 15px 0;
        background: #ffffff;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        z-index: 9;
    }
    header .navbar .navbar-collapse .navbar-nav li .nav-link{
        font-size: 16px;
    }
}
@media screen and (max-width:$medium_phone) {
    header .navbar .logo img {
        width: 130px;
        height: 33px;
    }
    header .navbar .logo {
        margin: 0 4px;
    }
    header .navbar .profile .profile_inner{
        margin: 0;
    }
    header .navbar .profile ul {
        margin-top: 20px;
    }
}
@media screen and (max-width:$small_phone) {
    // header .navbar .profile .profile_inner .profile_desc{
    //     display: none;
    // }
    // header .navbar .profile .profile_inner:after{
    //     display: none;
    // }
    header .navbar .profile ul{
        right: 0;
        left: auto;
    }
}