@import '../../assets/css/variable/variable';

.product_details {
    .main_block {
        .product_block {
            .side {
                margin: 0 0 30px;

                &.left_side {
                    .product_img {
                        img {
                            // width: 150px;
                            // min-width: 150px;
                            // height: 130px;
                            // object-fit: contain;
                            //border: 1px solid white;
                            margin-bottom: 20px;
                            // border: 1px solid #ccc;
                            border-radius: 5px;
                            overflow: hidden;
                        }
                    }
                }

                &.right_side {
                    .product_desc {
                        display: inline-block;

                        .pro_info {
                            margin: 0 0 20px;

                            h2 {
                                font-size: 31px;
                                line-height: 34px;
                                font-weight: 800;
                                color: $bodycolor;
                                text-transform: capitalize;
                            }

                            .linkto {
                                color: $bodyhovercolor;

                                &:hover {
                                    color: $bodycolor;
                                }
                            }
                        }
                    }

                    .product_item {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        gap: 10px 20px;
                        margin: 0 0 25px;

                        .part {
                            &.left_part {
                                min-width: 85px;

                                label {
                                    display: block;
                                    margin: 0;
                                    color: $bodycolor;
                                    font-size: $font_large;
                                    font-weight: 500;
                                }

                                .linkto {
                                    color: $bodyhovercolor;

                                    &:hover {
                                        color: $bodycolor;
                                    }
                                }
                            }

                            &.right_part {
                                .form_field_wrapper {
                                    margin: 0;
                                }
                            }
                        }

                        .form_field_wrapper {
                            margin: 0;
                            width: 300px;

                            .form-control {
                                font-weight: 500;
                            }

                            .custom_radiobtn {
                                margin: 0;
                            }
                        }

                        .btn {
                            width: 100%;
                        }

                        .price_total {
                            margin-top: 10px;

                            color: green;
                        }

                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width:$medium_phone) {
    .product_details .main_block .product_block .side.right_side .product_item {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media screen and (max-width:$small_phone) {
    .product_details .main_block .product_block .side.right_side .product_item .form_field_wrapper {
        width: 250px;
    }
}