@import 'variable/variable';

@font-face {
    font-family: $font_family_body;
    src: url('../font/Inter/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: $font_family_body;
    src: url('../font/Inter/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: $font_family_body;
    src: url('../font/Inter/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: $font_family_body;
    src: url('../font/Inter/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
.norecordfound {
    position: relative;
    top: 10;
    left: 0;
    width: auto;
    height: auto;
    padding: 10px;
    margin: 10px;
    line-height: 1.8;
    border-radius: 5px;    
    font-family: sans-serif;
    font-weight: 500;
  }
  .notice {
    background-color: #EFF;
    border: 1px solid #DEE;
    color: rgb(241 34 92);
  }

  .alertText {
    display: table;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
  }
  

.disabled-link {
    pointer-events: none;
  }
#__react-alert__ div div div {
    width: auto !important;
    text-transform: initial !important
}

.alert {
    margin-top: 15px;
    padding: 10px;
    background-color: #3a2e2d; /* Black */
    color: white;
    margin-bottom: 15px;
}

body {
    font-family: $font_family_body, $font_sans_sarif;
    font-weight: 400;
    line-height: 22px;
    color: $bodycolor;
    background-color: $bodybackground;
    font-size: $font_body;
}
label{
    color: $labelcolor;
    font-size: $font_medium;
    line-height: 19px;
    margin: 0 0 10px;
}
img{
    max-width: 100%;
}
hr{
    border-top: 1px dashed;
    margin: 2rem 0;
}
.req{
    color: $bodysecondarycolor
}
.error{
    color: $bodysecondarycolor;
    margin-bottom: 10px;
    line-height: 22px;
    font-size: $font_small;
}
.back_link{
    margin-top: 30px;
    .action_link{
        font-weight: 600;
        font-size: 35px;
        line-height: 42px;
        text-decoration: none;
        svg{
            margin-right: 10px;
        }
    }
}
.main_title{
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
    color: #212C2D;
}
.title_wrapper{
    .main_title{
        text-transform: uppercase;
        color: $bodyhovercolor;
        font-size:  $font_large;
        line-height: 20px;
        font-weight: 700;
        letter-spacing: 2.7px;    
    }
    .sub_title{
        font-size: 32px;
        line-height: 32px;
        font-weight: 500;
        color: $bodycolor;
    }
    .desc{
        color: #979797;
        font-size: 17px;
    }
    .link{
        color: $bodyhovercolor;
        &:hover{
            color: $bodycolor;
        }
    }
}
a{
    color: $bodycolor;
}
a:hover,a:focus{
    color: $bodysecondarycolor;
}
// .container{
//     outline: 1px solid red;
// }

// Login page css start
.login{
    background-color: $white_color;
    .login_inner{
        overflow: hidden;
            .left_part{
                background-image: url(../img/login_background.png);
                background-position: center; 
                padding: 0;
                background-repeat: no-repeat;
                background-size: cover;
                .leftpart_inner{
                    @include loginpart;
                    .logo{  
                        img{
                            width: 396px;
                            height: 115px;
                        }
                    } 
                }    
            }
            .right_part{
                padding: 0;
                .rightpart_inner{ 
                    @include loginpart;
                    form{
                        width: 455px;
                        margin: 0 auto;
                        .login_title{
                            font-weight: 600;
                            font-size: 36px;
                            line-height: 44px;
                            color: $bodycolor;
                            margin: 0 0 35px;
                            padding: 0 0 22px;
                            position: relative;
                            &:after{
                                content: "";
                                display: block;
                                width: 37px;
                                height: 4px;
                                background: $bodysecondarycolor;
                                border-radius: 3px;
                                position: absolute;
                                bottom: 0;
                                top: auto;
                                left: 0;
                                right: auto;
                            }
                        }
                        .btn {
                            min-width: 200px;
                        }
                        .login_desc{
                            color: $bodyopacitycolor;
                            margin: 0 0 30px;
                        }
                        .recoverpass{
                            margin: 25px 0 0;
                        }
                        .state_btn{
                            margin: 25px 0 0;  
                        }
                        .signup_btn{
                            margin: 25px 0 0;
                        }
                        .form_field_wrapper{
                            margin: 0 0 15px;
                            label{
                                color: $labelcolor;
                                font-size: $font_medium;
                                line-height: 19px;
                                margin: 0 0 10px;
                            }
                            .form-control{
                                @include formfield;
                                &:focus{
                                    color: $bodycolor;
                                    box-shadow: none;
                                    background: rgba(201, 203, 205, 0.1);
                                }
                            }
                            .linkto{
                                color: $bodysecondarycolor;
                                text-decoration: transparent;
                                &:hover{
                                    text-decoration: underline;
                                }
                                &.signuplink{
                                    color: $bodycolor;
                                    text-decoration: none;
                                    .signup_txt{
                                        color: $bodysecondarycolor;
                                        font-weight: 600;
                                        text-transform: uppercase;
                                    }
                                    &:hover{
                                        .signup_txt{
                                            color: $bodycolor;
                                        }
                                    }
                                }
                                &.backtologin{
                                    color: $bodycolor;
                                    text-decoration: none;
                                    .login_txt{
                                        color: $bodysecondarycolor;
                                        font-weight: 600;
                                        text-transform: uppercase;
                                    }
                                    &:hover{
                                        .login_txt{
                                            color: $bodycolor;
                                        }
                                    }
                                }
                            }
                            &.forgotpass{
                                margin: 0 0 30px;
                            }
                            &.signuplink_block{
                                margin: 20px 0 0;
                            }
                            &.backtologin_block{
                                margin: 25px 0 0;
                            }
                        }


                        // .title_wrapper{
                        //     text-align: center;
                        //     margin: 0 0 25px;
                        //     .form_title{
                        //         font-family: $font_family_body, $font_sans_sarif; 
                        //         text-transform: capitalize;
                        //         font-weight: 600;
                        //         font-size: 20px;
                        //         line-height: 24px;
                        //         color: $black_light_color;           
                        //         margin: 0 0 15px;
                        //     }
                        //     .form_desc{
                        //         font-weight: 400;
                        //         font-size: 14px;
                        //         line-height: 22px;
                        //         color: #3A3737;
                        //         span{
                        //             color: $bodycolor;
                        //             font-weight: 500;
                        //         }
                        //         &.pass_reset{
                        //             width: 65%;
                        //             margin: 0 auto;
                        //         }
                        //     }
                        //     .form_subdesc{
                        //         color: #3A3737; 
                        //         margin-top: 15px;
                        //     }
                        // }
                        // .form-btn{
                        //     .login_btn{
                        //         @include commonbtn;  
                        //         width: 100%;
                        //         margin: 0;
                        //     }
                        // } 
                    }
                }
            }
    }
    &.login_acc{
        .login_inner{
            .left_part{
                .leftpart_inner{
                    flex-direction: column;
                    text-align: center;
                    padding-left: 35px;
                    padding-right: 35px;
                    .part{
                        margin:0 0 50px;
                        &.top_part{
                            h3{
                                font-size: $font_large;
                                font-weight: 700;
                                letter-spacing: 2px;
                                color: $bodyhovercolor;
                                text-transform: uppercase;
                            }
                            h1{
                                font-size: 32px;
                                font-weight: 500;
                                line-height: 39px;
                            }
                        }
                        &.middle_part{
                            .service_title{
                                font-size: 17px;
                                font-weight: 500;
                                line-height: 24px;
                                margin: 0 0 40px;
                            }
                            .item_wrapper{
                                display: flex;
                                justify-content: center;
                                margin: 0 -15px;
                                flex-wrap: wrap;
                                .item{
                                    padding: 0 15px;
                                    width: 25%;
                                    margin: 0 0 35px;
                                    .service_img{
                                        width: 77px;
                                        height: 77px;
                                        line-height: 77px;
                                        margin: 0 auto;
                                        border-radius: 50%;
                                        background: #F8F8F8;
                                    }
                                }
                            }
                        }
                    }
                }    
            }
        } 
    }
    &.sign_up{
        .login_inner{
            .left_part{
                .leftpart_inner{
                    flex-direction: column;
                    text-align: center;
                    padding-left: 35px;
                    padding-right: 35px;
                    .part{
                        margin:0 0 50px;
                        &.top_part{
                            h3{
                                font-size: $font_large;
                                font-weight: 700;
                                letter-spacing: 2px;
                                color: $bodyhovercolor;
                                text-transform: uppercase;
                            }
                            h1{
                                font-size: 32px;
                                font-weight: 500;
                                line-height: 39px;
                            }
                        }
                        &.middle_part{
                            .service_title{
                                font-size: 17px;
                                font-weight: 500;
                                line-height: 24px;
                                margin: 0 0 40px;
                            }
                            .item_wrapper{
                                display: flex;
                                justify-content: center;
                                margin: 0 -15px;
                                flex-wrap: wrap;
                                .item{
                                    padding: 0 15px;
                                    width: 25%;
                                    .service_img{
                                        width: 77px;
                                        height: 77px;
                                        line-height: 77px;
                                        margin: 0 auto 10px;
                                        border-radius: 50%;
                                        background: #F8F8F8;
                                    }
                                    .service_desc{
                                        font-weight: 500;
                                        font-size: $font_small;
                                    }
                                }
                            }
                        }
                        &.bottom_part{
                            margin: 0;
                            h6{
                                font-size: $font_extra_small;
                                font-weight: 700;
                                text-transform: uppercase;
                                letter-spacing: 2px;
                            }
                            .item_wrapper{
                                margin: 40px -15px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .item{
                                    padding: 0 15px;  
                                    width: 25%;
                                }
                            }
                            p{
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 20px;
                                color: #0E0E0E;
                            }
                        }
                    }
                }    
            }
        } 
    }
}

// Common form fields
.form_field_wrapper{
    margin: 0 0 15px;
    label{
        color: $labelcolor;
        font-size: $font_medium;
        line-height: 19px;
        margin: 0 0 10px;
    }
    .form-control{
        @include formfield;
        &:focus{
            color: $bodycolor;
            box-shadow: none;
            background: rgba(201, 203, 205, 0.1);
        }
    }
    select{
        @include formfield;
        width: 100%;
        display: block;
        padding: 13px 15px;
        &:focus-visible{
            outline: none;
        }
    }
    &.date_picker{
        .MuiTextField-root{
            display: block;
            .MuiInputBase-root{
                width: 100%;
                .MuiInputBase-input{
                    height: auto;
                    padding: 13px 15px;
                    line-height: 24px;
                }
                fieldset{
                    border-color: rgba(14, 59, 89, 0.2);
                    margin: 0 !important;
                }
                button{
                    min-width: auto;
                }
            }
        }
    }


    .MuiFormControl-root{
        display: flex;
        border-radius: 5px;
        background-color: $formfiledbackgroundcolor;
        .MuiInputBase-root{
            .MuiInputBase-input{
                height: 50px;
                box-sizing: border-box;
                padding: 12px 30px 12px 15px;
                font-family: $font_family_body;
                font-weight: 500;
                color: $bodycolor;
            }
            .MuiOutlinedInput-notchedOutline{
                border-radius: 5px;
                border: 1px solid rgba(14, 59, 89, 0.2);
            }
        }
    }

}

.MuiPickersPopper-root{
    button{
        min-width: auto;
    }
}


// Searchbar css
.search_bar{
    .input-group{
        background: $white_color;
        border: 1px solid rgba(14, 59, 89, 0.2);
        border-radius: 100px;
        flex-wrap: nowrap;
        .form-control{
            border: none;
            background-color: transparent;
            padding: 15px 15px 15px 15px;
            width: 280px;
            &::placeholder{
                font-weight: 400;
                line-height: 24px;
                color: rgba(14, 59, 89, 0.5);
            }
            &:focus{
                box-shadow: none;
            }
        }
        .btn{
            padding: 0;
            border: none;
            min-width: 50px;
            color: rgba(14, 59, 89, 0.5);
            .mat-icon{
                line-height: 50px;
                height: 50px;
                width: 50px;
                color: #9B9B9B;
                font-size: 24px;
            }
        }
    }
}

// Dashboard css Start
.main_content{
    .main_block{
        background-color: $white_color;
        margin: 30px 0;
        padding: 20px;
        border-radius: 5px;
        .top_bar{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 20px;
            .right_part{
                .right_part_inner{
                    display: flex;
                    justify-content: center;
                    .add_kit{
                        margin: 0 0 0 20px;
                        padding: 17px 12px;
                    }
                }
            }
           
        }
    }
}



// Table css start
.table_wrapper{
    .table_inner{
        .MuiTableContainer-root {
            box-shadow: none;
            border-radius: unset;
            table{
                thead{
                    .MuiTableRow-head {
                        background: $bodycolor;
                        .MuiTableCell-root{
                            color: $white_color;
                            font-weight: 500;
                            font-size: $font_medium;
                            border-bottom:none;
                            font-family: $font_family_body,$font_sans_sarif;
                            text-align: left;
                            &:first-child{
                                border-radius: 5px 0 0 5px;
                            }
                            &:last-child{
                                border-radius: 0 5px 5px 0;
                            }
                        }
                    }
                }
                tbody{
                    .MuiTableRow-root{
                        .MuiTableCell-root{
                            // color: #212C2D;
                            font-weight: 500;
                            font-size: $font_medium;
                            border-bottom:none;
                            font-family: $font_family_body,$font_sans_sarif;
                            // position: relative;
                            text-align: left;
                            &:first-child{
                                border-radius: 5px 0 0 5px;
                            }
                            &:last-child{
                                border-radius: 0 5px 5px 0;
                            }
                        }
                        &:nth-child(even){
                            background-color: #F5F7F8;
                            border-radius: 5px;
                        }
                        .icon_group{
                            .action_link{
                                color: $bodysecondarycolor;
                                margin-left: 12px;
                                padding-left: 12px;
                                border-left: 1px solid rgba(14, 59, 89, 0.5);
                                &:hover{
                                    color: $bodycolor;
                                }
                                &:first-child{
                                    border: none;
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Modal css start
.MuiModal-root{
    .modal_block{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgb(255, 255, 255);
        border-radius: 31px;
        padding: 15px 25px 30px;
        .modal_header{
            .modal_title_wrapper{
                border-bottom: 1px solid rgba(14, 59, 89, 0.3);
                padding: 0 0 15px;
                margin: 0 0 20px;
                .modal_title{
                    font-weight: 600;
                    font-size: 25px;
                    line-height: 28px;
                    color: $bodycolor;
                    margin: 0 0 7px;
                }
                .modal_description{
                    font-weight: 400;
                    font-size: $font_medium;
                    line-height: 19px;
                    color: $bodyopacitycolor;
                    margin: 0;
                }
            }   
        }
        .modal_content{
            .form_field_wrapper{
                margin: 0 0 20px;
            }
            .kit_item{
                width: 33.33%;
            }
            .primary_btn{
                justify-content: center;
                flex-wrap: wrap;
                .btn{
                    min-width: 185px;
                    margin: 15px 0 0 15px;
                    &.cancel{
                        background-color: rgba(14, 59, 89, 0.1);
                        border-color: rgba(14, 59, 89, 0.1);
                        color: $bodycolor;
                        &:hover{
                            background-color: $btn_primary_color ;
                            border-color: $btn_primary_color;
                            color: $white_color;
                        }
                    }
                }
                
            }
        }
        &.eligible_modal{
            padding: 60px 30px 30px;
            .modal_content{
                text-align: center;
                span{
                    color: $bodyhovercolor;
                    font-size: $font_medium;
                    font-weight: 600;
                }
                h2{
                    font-size: 25px;
                    font-weight: 600;
                    color: $bodycolor;
                    margin: 15px 0 20px;
                }
                p{
                    color: $bodycolor;
                    font-size: $font_medium;
                    font-weight: 400;
                    width: 85%;
                    margin: 0 auto;
                }
                .primary_btn {
                    .btn{
                        min-width: 205px;
                        margin: 40px 0 0;
                    }
                }
            } 
        }
    }
}



// Tabs common css
.tabs{
    .MuiTabs-root{
        border-bottom: 1px solid $bodysecondarycolor;
        .MuiTabScrollButton-root{
            box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
            margin: 3px;
            &.Mui-disabled{
                display: none;
            }
        }
        button{
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: $bodycolor;
            text-transform: capitalize;
            border-radius: 5px;
            min-height: 50px;
            min-width: 255px;
            font-family: $font_family_body,$font_sans_sarif;
            &.Mui-selected{
                color: $white_color;
                background-color: $bodysecondarycolor;
            }
        }
        .MuiTabs-indicator{
            background-color: $bodysecondarycolor;
            transition: none;
            display: none;
        }
    }
}

// Custom loader css
.overrelay{
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.2);
    z-index: 99;
    cursor: pointer;
    user-select: none;
    .custom_loader{
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}


// Checkbox custom css
.custom_checkbox{
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: $font_small;
    line-height: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input{
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~{
            .checkmark{
                background-color: $bodyhovercolor;
                border-color: $bodyhovercolor;
                &::after{
                    display: block;
                }
            }
        }
    }
    .checkmark{
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: $white_color;
        border: 1.5px solid #0E3B5933;
        border-radius: 5px;
        &:after{
            content: "";
            position: absolute;
            display: none;
            left: 5px;
            top: 4px;
            width: 5px;
            height: 8px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

// Radio Button custom css
.custom_radiobtn{
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: $font_small;
    line-height: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input{
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~{
            .checkmark{
                background-color: $bodyhovercolor;
                border-color: $bodyhovercolor;
                &::after{
                    display: block;
                }
            }
        }
    }
    .checkmark{
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: $white_color;
        border: 1px solid #B0B0B0;
        border-radius: 50%;
        &:after{
            content: "";
            position: absolute;
            display: none;
            left: 4px;
            top: 4px;
            width: 8px;
            height: 8px;
            background: #ffffff;
            border-radius: 50%;
        }
    }
}


// Breadcum css start
.breadcum_wrapper{
    background: #F6F8FC;
    padding: 90px 0;
    background-image: url(../../assets/img/breadcum_about.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
        .breadcrumb_title{
            font-weight: 500;
            font-size: 48px;
            line-height: 58px;
            color: $bodycolor;
        }
        .breadcrumb{
            margin: 0 0 5px;
            li{
                font-weight: 400;
                font-size: $font_large;
                line-height: 19px;
                color: $bodycolor;
                a{
                    text-decoration: none;
                    color: $bodycolor;
                }
            }
        }
}


// Owl-carusel css start
.owl-carousel{
    .owl-dots{
        text-align: center;
        margin-top: 30px;
        .owl-dot{
            min-width: auto;
            margin:  0 5px;
            span{
                width: 18px;
                height: 18px;
                line-height: 18px;
                border-radius: 50%;
                background: #FDB6BE;
                display: inline-block;
                vertical-align: top;
            }
            &.active{
                span{
                    background: $bodyhovercolor;  
                }
            }
        }
    }
}


// Cms page css
.cms_page{
    padding: 60px 0;
    .title_wrapper{
        margin: 0 0 40px;
        .main_title{
            font-size: 18px;
            font-weight: 700;
            line-height: 22px; 
        }
        .sub_title{
            font-size: 32px;
            font-weight: 500;
            line-height: 32px;
        }
    }
    .content{
        margin: 0 0 30px;
        .content_title{
            color: #5B5B5B;
        }
        p{
            font-size: 17px;
            line-height: 24px;
            color: #979797;
            margin: 0;
            a{
                color: $bodyhovercolor;
            }
        }
    }
}