@import '../../../assets/css/variable/variable';
.intake_form{
   .tabs{
      .MuiTabPanel-root{
        padding: 0;
        .tabpanel_inner {
            padding: 25px 0;
            .form_title{
                font-weight: 400;
                font-size: 35px;
                word-break: break-word;
                line-height: 42px;
                color: #212C2D;
                margin: 0 0 15px;
            }
            &.personal_info{
                form{
                    fieldset{
                        margin: 0 0 10px;
                        .form_field_wrapper{
                            .upload_input{
                                position: relative;
                                .user_upload_content{
                                    display: flex;
                                    flex-direction: column;
                                    min-height: 160px;
                                    justify-content: center;
                                    align-items: center;
                                    background: rgba(201, 203, 205, 0.1);
                                    border: 1px solid rgba(14, 59, 89, 0.2);
                                    border-radius: 5px;
                                    label{
                                        font-weight: 300;
                                        font-size: 14px;
                                        line-height: 17px;
                                        color: $black_color;
                                        margin: 7px 0 15px;
                                    }
                                    span{
                                        font-weight: 500;
                                        font-size: 18px;
                                        line-height: 22px;
                                        color: $successcolor;
                                        text-decoration: underline;
                                    }
                                }
                                .user_upload_input{
                                    width: 100%;
                                    min-height: 160px;
                                    opacity: 0;
                                    position: absolute;
                                    top: 0;
                                    bottom: auto;
                                }
                            }
                        } 
                        .form_group_content{
                            margin: 40px 0;
                            .form_label{
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 24px;
                                color: #212C2D;
                                margin: 0 0 7px;
                            }
                            .MuiFormGroup-root{
                                .MuiFormControlLabel-label{
                                    font-family: $font_family_body,$font_sans_sarif;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: rgba(0, 0, 0, 0.8);
                                }
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }  
                    }
                }
             
            }
        }
      }
   }
}
.international-phone-container{
        width: 408px;
        height: 50px;
        font-size: 14px;
}

.international-phone-container input.form-control {
   
    height: 50px;
    font-size: 14px;
}

@media screen and (max-width:$tablet) {
    .intake_form .main_block .top_bar .main_title {
        font-size: 22px;
        line-height: 26px;
    }
    .intake_form .main_block .top_bar .sub_title{
        font-size: 16px;
    }
    .intake_form .tabs .MuiTabPanel-root .tabpanel_inner .form_title{
        font-size: 22px;
        line-height: 26px;
    }
}
@media screen and (max-width:$medium_phone) {
.intake_form .tabs .MuiTabPanel-root .tabpanel_inner.personal_info form fieldset .form_group_content {
    margin: 20px 0;
}
}
@media screen and (max-width:$small_phone) {
    .intake_form .main_block .top_bar .sub_title {
        font-size: 14px;
    }
}

