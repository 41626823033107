@import '../../assets/css/variable/variable';

.payment_page {
    .main_block {
        padding: 30px;

        .purchase_detail {
            .part_wrapper {
                margin: 0 -30px;

                .part {
                    padding: 0 30px;
                    border-right: 1px solid #D2D2D2;

                    &:last-child {
                        border: none;
                    }

                    &.left_part {
                        .title_wrapper {
                            margin: 0 0 35px;

                            .title {
                                font-size: 30px;
                                font-weight: 600;
                                line-height: 30px;
                                color: #212C2D;
                                margin: 0 0 8px;
                            }

                            .desc {
                                font-size: $font_ex_large;
                                font-weight: 400;
                                line-height: 24px;
                                color: #212C2D;
                            }
                        }

                        .content_item {
                            margin: 0 0 40px;

                            .content_title {
                                font-size: 35px;
                                font-weight: 400;
                                line-height: 35px;
                                color: #212C2D;
                                margin: 0 0 25px;
                            }

                            .form_field_wrapper {
                                margin: 0 0 25px;

                                label {
                                    font-weight: 600;
                                    color: $blacklight_color;

                                    span {
                                        color: $bodyhovercolor;
                                    }
                                }
                            }

                            &.shipping_meathod {
                                .shipping_block {
                                    border: 1px solid #0E3B5933;
                                    border-radius: 5px;
                                    overflow: hidden;

                                    .radiobtn_item {
                                        padding: 15px;
                                        border-bottom: 1px solid #0E3B5933;
                                        background-color: #F2F7FF;

                                        .custom_radiobtn {
                                            margin: 0;
                                            font-size: $font_medium;
                                            font-weight: 300;
                                        }

                                        &:last-child {
                                            border: none;
                                            background-color: transparent;
                                        }
                                    }
                                }
                            }

                            &.payment_meathod {
                                .content_desc {
                                    font-size: $font_medium;
                                    font-weight: 500;
                                    line-height: 19px;
                                    color: $blacklight_color;
                                    margin: 0 0 25px;
                                }

                                .purchase {
                                    border-radius: 5px;
                                    font-size: $font_ex_large;
                                    font-weight: 600;
                                    text-transform: uppercase;
                                    min-width: 200px;
                                    min-height: 78px;
                                    margin: 0 0 15px;
                                }

                                .pay_desc {
                                    font-size: $font_small;
                                    font-weight: 300;
                                    line-height: 19px;
                                    color: $black_color;
                                }
                            }

                        }
                    }

                    &.right_part {
                        .product_card {
                            border: 1px solid #0E3B5933;
                            background-color: #fafafa;
                            border-radius: 5px;
                            padding: 30px;

                            .product_item {
                                display: flex;
                                align-items: center;
                                gap: 0 15px;
                                margin: 0 0 20px;

                                .pro_image {
                                    img {
                                        border: 1px solid #dddddd;
                                        border-radius: 5px;
                                        width: 69px;
                                        min-width: 69px;
                                        height: 69px;
                                    }
                                }

                                .pro_desc {
                                    .title {
                                        font-size: $font_small;
                                        font-weight: 300;
                                        color: $black_color;
                                        margin: 0 0 3px;
                                    }

                                    .View_detail {
                                        color: $successcolor;
                                        font-size: $font_extra_small;
                                    }
                                }
                            }

                            .price_block {
                                .item {
                                    display: flex;
                                    justify-content: space-between;
                                    gap: 15px;
                                    flex-wrap: wrap;
                                    margin: 0 0 12px;

                                    label {
                                        margin: 0;
                                        font-size: $font_small;
                                        font-weight: 300;
                                        line-height: 17px;
                                        color: $black_color;
                                    }

                                    .value {
                                        font-size: $font_small;
                                        font-weight: 300;
                                        line-height: 17px;
                                        color: $black_color;

                                        &.shipp_add {
                                            font-size: $font_extra_small;
                                            color: #7D7D7D;
                                        }
                                    }

                                    &.total {
                                        padding-top: 12px;
                                        border-top: 1px dashed #0E3B5933;

                                        .value {
                                            font-size: $font_medium;

                                            .currency {
                                                font-size: $font_extra_small;
                                                color: #7D7D7D;
                                                margin: 0 15px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }


                }
            }
        }
    }
}

@media screen and (max-width:$large_phone) {
    .payment_page .main_block .purchase_detail .part_wrapper .part {
        margin: 0 0 30px;
        border-bottom: 1px solid #D2D2D2;
        border-right: none;
    }
}

@media screen and (max-width:$medium_phone) {
    .payment_page .main_block .purchase_detail .part_wrapper .part.right_part .product_card {
        padding: 25px;
    }

    .payment_page .main_block .purchase_detail .part_wrapper .part.left_part .title_wrapper .title {
        font-size: 26px;
        line-height: 26px;
    }

    .payment_page .main_block .purchase_detail .part_wrapper .part.left_part .title_wrapper .desc {
        font-size: 16px;
    }

    .payment_page .main_block .purchase_detail .part_wrapper .part.left_part .content_item .content_title {
        font-size: 28px;
        line-height: 28px;
    }
}