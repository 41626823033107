@import '../../assets/css/variable/variable';
.main_content{
    .change_password{
        width: 545px;
        margin: 30px auto;
        form{
            .main_title{
                border-bottom: 1px solid rgba(14, 59, 89, 0.15);
                padding-bottom: 15px;
                margin-bottom: 20px;
                font-size: 28px;
                line-height: 34px;
            }
            .primary_btn{
                margin-top: 25px;
            }
        }
  
    }
}

.container_change_password{
        display: flex;
        justify-content: center;
        align-items: center;    }

        
@media screen and (max-width: $medium_iphone) {
    .main_content .change_password{
        width: 100%;
    }
}
