@import '../../../assets/css/variable/variable';

.patient_details{
    .main_block{
        .main_title{
            color: $bodyhovercolor;
            margin: 0 0 10px;
        }
        .sub_title{
            font-size: 22px;
            font-weight: 600;
            color: $formfiled_color;
        }
        .tabs{
            display: flex;
            gap: 15px 10px;
            margin-top: 40px;
            .MuiTabs-root{
                border-bottom: none;
                .MuiTabs-flexContainer{
                    flex-direction: column;
                    button{
                        color: $bodycolor;
                        background-color: #fbfbfb;
                        border: 1px solid #ebebeb;
                        border-radius: 8px;
                        width: 100%;
                        &.Mui-selected{
                            color: $white_color;
                            background-color: $bodysecondarycolor;
                            border-color: $bodyhovercolor;
                        }
                    }
                }
            }
            .MuiTabPanel-root{
                padding: 0;
                width: 100%;
            }
            .tabpanel_inner {
                .tab_data{
                    .tab_title{
                        font-size: $font_medium;
                        font-style: italic;
                        font-weight: 400;
                        line-height: 19px;
                        color: #212C2D;
                        margin: 15px 0;
                        padding: 0 20px;
                    }
                    .item_wrapper{
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        border: 1px solid rgba(33, 44, 45, 10%);
                        border-radius: 5px;
                        .item{
                            border-bottom: 1px solid rgba(33, 44, 45, 10%);
                            display: flex;
                            min-height: 54px;
                            height: 100%;
                            label{
                                margin: 0;
                                font-size: $font_medium;
                                font-weight: 600;
                                line-height: 22px;
                                color: #212C2D;
                                padding: 10px 20px;
                                width: 250px;
                                align-items: center;
                                display: flex;
                                position: relative;
                                &::after{
                                    border-right: 1px solid rgba(33, 44, 45, 0.1);
                                    content: "";
                                    display: block;
                                    height: 100%;
                                    position: absolute;
                                    top: 0;
                                    bottom: auto;
                                    right: 0;
                                    left: auto;
                                }
                            }
                            .value{
                                font-size: $font_medium;
                                font-weight: 400;
                                color: #646464;
                                padding: 10px 20px;
                                width: 100%;
                                align-items: center;
                                display: flex;
                            }
                            &:nth-child(even){
                                background-color: #F5F7F8;
                            }
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                }
                &.documents{
                    .tab_data{
                        .doc_item_wrapper{
                            display: flex;
                            flex-wrap: wrap;
                            gap: 30px 45px;
                            justify-content: center;
                            .doc_item{
                                .doc_item_inner{
                                    .doc_title{
                                        font-size: $font_medium;
                                        font-style: italic;
                                        font-weight: 400;
                                        color: #212C2D;
                                        padding: 0 20px;
                                        margin: 0 0 15px;
                                    }
                                    .doc_img{
                                        border: 1px solid rgba(33, 44, 45, 10%);
                                        padding: 15px;
                                        background-color: #F5F7F8;
                                        img{
                                            object-fit: contain;
                                            width: 290px;
                                            height: 174px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.uppercase {
    text-transform: capitalize;
}
@media screen and (max-width:$desktop) {
    .patient_details .main_block .tabs .tabpanel_inner.documents .tab_data .doc_item_wrapper{
        gap: 30px;
    }
    .patient_details .main_block .tabs .tabpanel_inner.documents .tab_data .doc_item_wrapper .doc_item .doc_item_inner .doc_img img{
        width: 280px;
        height: 168px;
    }
}
@media screen and (max-width:$tablet) {
    .patient_details .main_block .tabs .tabpanel_inner.documents .tab_data .doc_item_wrapper{
        gap: 30px 15px;
    }
    .patient_details .main_block .tabs .tabpanel_inner.documents .tab_data .doc_item_wrapper .doc_item .doc_item_inner .doc_img img{
        width: 204px;
        height: 122px;
    }
}
@media screen and (max-width:$large_phone) {
    .patient_details .main_block .tabs{
        flex-direction: column;
    }
}

@media screen and (max-width:$medium_iphone) {
    .patient_details .main_block .tabs .tabpanel_inner .tab_data .item_wrapper .item{
        flex-direction: column;
    }
    .patient_details .main_block .tabs .tabpanel_inner .tab_data .item_wrapper .item label::after{
        border-bottom: 1px solid rgba(33, 44, 45, 0.1);
        border-right: none;
        top: auto;
        bottom: 0;
        right: 0;
        left: 0;
    }
    .patient_details .main_block .tabs .tabpanel_inner .tab_data .item_wrapper .item label{
        width: 100%;
    }
    .patient_details .main_block .tabs .tabpanel_inner.documents .tab_data .doc_item_wrapper .doc_item .doc_item_inner .doc_img img{
        width: 290px;
        height: 174px;
    }
}