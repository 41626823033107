@media (max-width: 768px) {
    .top_bar {
      flex-direction: column; // Stack items vertically in mobile view
      align-items: flex-start;

      .right_part {
        width: 100%;

        .right_part_inner {
          flex-direction: column; // Stack the search bar and button vertically
          align-items: stretch;

          .search_bar {
            margin-bottom: 10px; // Space between search and button
            text-align: center;
            .form-control {
              width: 100%; // Full width in mobile view
            }
          }

          .add_kit {
            width: 80%; // Full width for the button
            text-align: center;
          }
        }
      }
    }
  }










  // OrderView.module.scss

.order-view-container {
    padding: 20px;
  
    // Grid container for the main layout
    .order-grid-container {
      display: flex;
      justify-content: center;
      padding: 20px;
  
      // Grid item for the main card container
      .order-grid-item {
        width: 100%;
        max-width: 1200px;
  
        // Paper container for the inner layout
        .order-paper {
          padding: 30px;
          margin: 20px auto;
          background-color: #f8f9fa;
          border-radius: 10px;
  
          // Box for heading and back button
          .order-header {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
  
            .order-back-button {
              margin-right: auto;
            }
  
            .order-title {
              flex-grow: 1;
              text-align: center;
              font-weight: bold;
            }
          }
  
          // Grid container for product and payment details
          .order-details-grid {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
  
            // Left column - product and customer details
            .order-left-column {
              flex: 1 1 60%;
              display: flex;
              flex-direction: column;
  
              .order-card {
                background-color: #ffffff;
                border-radius: 10px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                margin-bottom: 20px;
                flex: 1;
  
                .order-card-content {
                  flex: 1;
  
                  .order-product-info,
                  .order-customer-info {
                    margin-bottom: 20px;
                  }
  
                  .order-divider {
                    margin: 20px 0;
                  }
                }
              }
            }
  
            // Right column - order and payment details
            .order-right-column {
              flex: 1 1 35%;
              display: flex;
              flex-direction: column;
  
              .order-card {
                background-color: #ffffff;
                border-radius: 10px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                margin-bottom: 20px;
                flex: 1;
  
                .order-card-content {
                  flex: 1;
  
                  .order-payment-info {
                    margin-bottom: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  // Responsive adjustments for smaller screens
  @media (max-width: 768px) {
    .order-view-container {
      padding: 10px;
  
      .order-grid-container {
        padding: 10px;
  
        .order-grid-item {
          .order-paper {
            padding: 20px;
  
            .order-header {
              flex-direction: column;
              align-items: flex-start;
  
              .order-title {
                text-align: left;
                margin-top: 10px;
              }
            }
  
            .order-details-grid {
              flex-direction: column;
  
              .order-left-column,
              .order-right-column {
                flex: 1 1 100%;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
  