 /* General styling for the login page */
/* .login {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .login_inner {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .left_part,
  .right_part {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .left_part {
    order: 1;
  }
  
  .right_part {
    order: 2;
  }
   */
  @media screen and (max-width: 768px) {
    .login_inner {
      flex-direction: column;
      padding: 20px;
    }
  
    .left_part {
      order: 2;
      margin-top: 20px;
    }
  
    .right_part {
      order: 1;
    }
  
    .login_form {
      width: 100%;
    }
  
    .service_img img {
      max-width: 80px;
      margin: 10px;
    }
  }
   
  /* Mobile view adjustments */
   @media screen and (max-width: 576px) {
    .left_part,
    .right_part {
      width: 100%;
      padding: 10px;
    }
  
    .login_form_inner {
      padding: 15px;
    }
  
    .form_field_wrapper {
      margin-bottom: 15px;
    }
  
    .login_form {
      margin-bottom: 30px;
    }
  }