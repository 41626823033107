@media (max-width: 768px) {
    .top_bar {
      flex-direction: column;
  
      .right_part {
        justify-content: center;
        width: 100%;
        margin-top: 1rem;
  
        .search_bar {
          width: 100%;
          margin-right: 0;
  
          .input-group {
            width: 100%;
  
            input {
              width: 100%;
              margin-right: 0;
            }
  
            .search-btn {
              width: 20%;
            }
          }
        }
  
        .status_dropdown {
          width: 70%;
          margin-top: 1rem;
  
          select {
            width: 100%;
          }
        }
      }
    }
  }