@import '../../../assets/css/variable/variable';

.intake_data{
    .main_block{
        .intake_form{
            .table_inner{
                table{
                    tbody{
                        tr{
                            .MuiTableCell-paddingCheckbox{
                                button{
                                    min-width: auto;
                                }
                            }
                        }
                    }
                    .tabs{
                        .MuiTabs-root{
                           border: none;
                        }
                    }
                }
              
               
            }
        }
    }
}
img.productImage{
    width: 100px; /* Set your desired fixed width */
    height: 80px; /* Set your desired fixed height */
    object-fit: cover; /* Maintain aspect ratio and cover container */
    border-radius: 8px; /* Optional: Add rounded corners */
}

@media screen and (max-width:$large_phone) {
    // .intake_data .main_block .intake_form .table_inner table tbody tr td.MuiTableCell-paddingCheckbox{
    //     width: auto; 
    //     align-items: flex-start;
    //     flex-direction:row;
    // }
    .intake_data .main_block .intake_form .table_inner table tbody .row_wrapper {
        display: flex;
        align-items: center;  
        margin: 0 0 0.5rem 0;  
    }
    .intake_data .main_block .intake_form .table_inner table tbody .row_wrapper .MuiTableCell-paddingCheckbox button{
        padding: 0;
    }
}