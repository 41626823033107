@import '../variable/breakpoints';

@media screen and (min-width:$medium_iphone_up) {
    .MuiModal-root .modal_block{
        width: 550px;
    }
    .modal-xl{
        max-width:550px;
    }
}
@media screen and (min-width:$tablet_up) {
    .MuiModal-root .modal_block{
        width: 610px;
    }
    .modal-xl{
        max-width:730px;
    }
    .modal-lg{
        max-width:730px;
    }
    .table_wrapper table tbody tr td .mob_head{
        display: none;
    }
    
}
@media screen and (min-width:$desktop_pro_up) {
    .container{
        padding: 0;
    }
    .modal-xl{
        max-width:960px;
    }
    .left_panel {
        width: 45%;
    }
    .right_panel {
        width: 55%;
    }
}
@media screen and (min-width:$desktop_up) {
    .modal-xl{
        max-width:1160px;
    }
    .login.sign_up .login_inner .right_part .rightpart_inner .login_form_inner form .custom_checkbox{
        white-space: nowrap;
    }
}
@media screen and (min-width:$desktop_medium_up) {
    .container{
        max-width: 1240px;
    }
}
@media screen and (min-width:$desktop_large_up) {
    .container{
        max-width: 1320px;
    }
    .modal-xl{
        max-width:1360px;
    }
    .cms_page .container{
        max-width: 1150px;
    }
}

@media screen and (max-width:1920px) {
    .table_inner{
        width: 100%;
        display: inline-block;
        vertical-align: top;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        &::-webkit-scrollbar{
            height: 3px; 
        }
        &::-webkit-scrollbar-thumb{
            background-color: #B5C8D8; 
            border-radius: 20px;
        }
    }
}

@media screen and (max-width:$desktop_large) {

}
@media screen and (max-width:$desktop) {
    .login .login_inner .right_part .rightpart_inner .login_form_inner form{
        width: 400px;
    }
}
@media screen and (max-width:$tablet) {
    .container{
        max-width: none;
        width: auto;
        margin-left: 15px;
        margin-right: 15px;
        padding: 0;
    }
    .login .login_inner .right_part .rightpart_inner .login_form_inner form{
        width: 325px;
    }
    .login .login_inner .left_part.part .leftpart_inner .logo img{
        width: 250px;
        height: 73px;
    }
    .login .login_inner .right_part .rightpart_inner .login_form_inner form .login_title{
        margin: 0 0 25px;
        padding: 0 0 18px;
        font-size: 30px;
        line-height: 34px;   
    }
    .main_title{
        font-size: 22px;
        line-height: 26px;
    }
    .tabs .MuiTabs-root button{
        font-size: 16px;
        min-width: 200px;
    }
    .login.login_acc .login_inner .left_part .leftpart_inner .part.top_part h1{ 
        font-size: 22px;
        line-height: 30px;
    }
    .login.login_acc .login_inner .left_part .leftpart_inner .part.middle_part .item_wrapper .item{
        width: 50%;
    }
    .login.sign_up .login_inner .left_part .leftpart_inner .part.top_part h1{ 
        font-size: 22px;
        line-height: 30px;
    }
    .login.sign_up .login_inner .left_part .leftpart_inner .part.middle_part .item_wrapper .item{
        width: 50%;
    }
    .breadcum_wrapper .breadcrumb_title{
        font-size: 34px;
        line-height: 34px;
    }
    .breadcum_wrapper{
        padding: 40px 0;
    }
}
@media screen and (max-width:$large_phone) {

    .login .login_inner .right_part .rightpart_inner .login_form_inner form{
        width: 100%;
    }
    .login .login_inner .row .left_part .leftpart_inner {
        min-height: 50vh;
        padding: 20px 0;
    }
    .login .login_inner .row .right_part .rightpart_inner{
        min-height: 75vh;
        padding: 40px 30px;
    }
    .login.login_acc .login_inner .left_part .leftpart_inner .part.middle_part .item_wrapper .item{
        width: 25%;
    }
    .login.login_acc .login_inner .left_part .leftpart_inner{
        padding: 20px;
    }
    .login.sign_up .login_inner .left_part .leftpart_inner{
        padding: 20px;
    }
    .main_content .main_block .top_bar{
        flex-direction: column;
    }
    .main_content .main_block .top_bar .right_part{
        margin-top: 20px;
        width: 100%;
    }
    .search_bar .input-group .form-control{
        width: 100%;
    }

    .tabs .MuiTabs-root .MuiTabs-flexContainer{
        flex-direction: column;
    }
    .tabs .MuiTabs-root button{
        min-width: 100%;
        margin: 0 0 8px;
    }
    .table_inner{
        overflow-x: unset;
        overflow-y: unset;
        white-space: normal;
    }
    .table_wrapper table,
    .table_wrapper tbody, 
    .table_wrapper th, 
    .table_wrapper td, 
    .table_wrapper tr {
        display: block;
        min-width: auto;
    }
    .table_wrapper thead{
        display: none;
    }
    .table_wrapper thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    .table_wrapper tbody tr {
        margin: 0 0 1rem 0;
    }
    .table_wrapper .table_inner .MuiTableContainer-root table tbody .MuiTableRow-root .MuiTableCell-root{
        white-space: break-spaces;
        word-wrap: break-word;
    }
    .table_wrapper tbody .MuiTableCell-root {
        display: flex;
        flex-direction: column;
        padding: 10px 15px;
    }
    .table_wrapper tbody .MuiTableCell-root:before,
    .table_wrapper table tbody tr .mob_head {
        margin-bottom: 5px;
        font-size: $font_medium;
        font-weight: 600;
        color: #212C2D;
    }
    // .test_overview tbody .MuiTableCell-root:nth-child(1):before { content: "Test Name"; }
    // .test_overview tbody .MuiTableCell-root:nth-child(2):before { content: "Results"; }
    // .test_overview tbody .MuiTableCell-root:nth-child(3):before { content: "Range"; }

    // .dashboard tbody .MuiTableCell-root:nth-child(1):before { content: "KIT"; }
    // .dashboard tbody .MuiTableCell-root:nth-child(2):before { content: "Patient Name"; }
    // .dashboard tbody .MuiTableCell-root:nth-child(3):before { content: "Report Status"; }
    // .dashboard tbody .MuiTableCell-root:nth-child(4):before { content: "Sample Received"; }
    // .dashboard tbody .MuiTableCell-root:nth-child(5):before { content: "Report ID"; }
    // .dashboard tbody .MuiTableCell-root:nth-child(6):before { content: "Report Date"; }
    // .dashboard tbody .MuiTableCell-root:nth-child(7):before { content: "Action"; }

    // .intake_form tbody .MuiTableCell-root:nth-child(1):before { content: "Patient Name"; }

    .personal_info tbody .MuiTableCell-root:nth-child(1):before { content: "First Name"; }
    .personal_info tbody .MuiTableCell-root:nth-child(2):before { content: "Last Name"; }
    .personal_info tbody .MuiTableCell-root:nth-child(3):before { content: "Weight"; }
    .personal_info tbody .MuiTableCell-root:nth-child(4):before { content: "Height"; }
    .personal_info tbody .MuiTableCell-root:nth-child(5):before { content: "Email"; }
    .personal_info tbody .MuiTableCell-root:nth-child(6):before { content: "Address1"; }
    .personal_info tbody .MuiTableCell-root:nth-child(7):before { content: "City"; }
    .personal_info tbody .MuiTableCell-root:nth-child(8):before { content: "State"; }
    .personal_info tbody .MuiTableCell-root:nth-child(9):before { content: "ZipCode"; }
    .personal_info tbody .MuiTableCell-root:nth-child(10):before { content: "Mobile Number"; }
    .personal_info tbody .MuiTableCell-root:nth-child(11):before { content: "Home Number"; }
    .personal_info tbody .MuiTableCell-root:nth-child(12):before { content: "Work Number"; }
    .personal_info tbody .MuiTableCell-root:nth-child(13):before { content: "Preferred Time For Contact"; }
    .personal_info tbody .MuiTableCell-root:nth-child(14):before { content: "Preferred Type Of Contact"; }
    .personal_info tbody .MuiTableCell-root:nth-child(15):before { content: "Gender"; }
    .personal_info tbody .MuiTableCell-root:nth-child(16):before { content: "Date Of Birth"; }
    .personal_info tbody .MuiTableCell-root:nth-child(17):before { content: "Driving License"; }

    .history tbody .MuiTableCell-root:nth-child(1):before { content: "Past Medical History"; }
    .history tbody .MuiTableCell-root:nth-child(2):before { content: "Past Surgical History"; }
    .history tbody .MuiTableCell-root:nth-child(3):before { content: "Current Medications"; }
    .history tbody .MuiTableCell-root:nth-child(4):before { content: "Current Supplements"; }
    .history tbody .MuiTableCell-root:nth-child(5):before { content: "Allergies"; }
    .history tbody .MuiTableCell-root:nth-child(6):before { content: "Colonoscopy"; }
    .history tbody .MuiTableCell-root:nth-child(7):before { content: "Prostate Exam"; }
    .history tbody .MuiTableCell-root:nth-child(8):before { content: "Mammogram"; }
    .history tbody .MuiTableCell-root:nth-child(9):before { content: "PapSmear"; }
    .history tbody .MuiTableCell-root:nth-child(10):before { content: "Do you have a current Primary Care Physician? If so list name:"; }
    .history tbody .MuiTableCell-root:nth-child(11):before { content: "Physician's Telephone"; }
    .history tbody .MuiTableCell-root:nth-child(12):before { content: "Have you had a physical in the last year?"; }
    .history tbody .MuiTableCell-root:nth-child(13):before { content: "Heart Attack, Stroke, High Blood Pressure, etc.."; }
    .history tbody .MuiTableCell-root:nth-child(14):before { content: "Diabetes, Thyroid or Other Endocrine Disorder"; }
    .history tbody .MuiTableCell-root:nth-child(15):before { content: "Cancer"; }
    .history tbody .MuiTableCell-root:nth-child(16):before { content: "Other Illnesses"; }
    .history tbody .MuiTableCell-root:nth-child(17):before { content: "Do you currently or have you ever used tobacco products?"; }
    .history tbody .MuiTableCell-root:nth-child(18):before { content: "Do you drink alcohol?"; }
    .history tbody .MuiTableCell-root:nth-child(19):before { content: "Do you use recreational drugs?"; }
    .history tbody .MuiTableCell-root:nth-child(20):before { content: "Do you exercise regularly?"; }
    .history tbody .MuiTableCell-root:nth-child(21):before { content: "Heart Attack, Stroke, High Blood Pressure, etc..(Others)"; }
    .history tbody .MuiTableCell-root:nth-child(22):before { content: "Diabetes, Thyroid or Other Endocrine Disorder ( Others )"; }
    .history tbody .MuiTableCell-root:nth-child(23):before { content: "Cancer (Others)"; }
    .history tbody .MuiTableCell-root:nth-child(24):before { content: "Other Illnesses Inputfield"; }

    .questions tbody .MuiTableCell-root:nth-child(1):before { content: "Health Goals"; }
    .questions tbody .MuiTableCell-root:nth-child(2):before { content: "Other Goals"; }

    .signature tbody .MuiTableCell-root:nth-child(1):before { content: "Patient Authorization Consent"; }
    .signature tbody .MuiTableCell-root:nth-child(2):before { content: "Privacy Policy Consent"; }
    .signature tbody .MuiTableCell-root:nth-child(3):before { content: "Telehealth Consent"; }
    .signature tbody .MuiTableCell-root:nth-child(4):before { content: "Therapy Management Consent"; }
    .signature tbody .MuiTableCell-root:nth-child(5):before { content: "I have read the text above, and I agree to the terms and conditions disclosed herein"; }
    .signature tbody .MuiTableCell-root:nth-child(6):before { content: "Digital Signature Please Print Name"; }
    .signature tbody .MuiTableCell-root:nth-child(7):before { content: "Electronic Signature"; }
    .signature tbody .MuiTableCell-root:nth-child(8):before { content: "I agree to the HIPAA Privacy Statement"; }

    .patient_kitid tbody .MuiTableCell-root:nth-child(1):before { content: "KIT ID"; }
    .patient_kitid tbody .MuiTableCell-root:nth-child(2):before { content: "Report Id"; }
    .patient_kitid tbody .MuiTableCell-root:nth-child(3):before { content: "Sample Received"; }
    .patient_kitid tbody .MuiTableCell-root:nth-child(4):before { content: "Report Date"; }
    .patient_kitid tbody .MuiTableCell-root:nth-child(5):before { content: "Action"; }
   


}
@media screen and (max-width:$medium_iphone) {
    .MuiModal-root .modal_block{
        width: 95%;
    }
    .login .login_inner .row .right_part .rightpart_inner .login_form{
        width: 100%;
    }
    .dashboard .table_inner .MuiTableContainer-root table tbody .MuiTableRow-root .icon_group .action_link,
    .dashboard .table_inner .MuiTableContainer-root table tbody .MuiTableRow-root .icon_group .action_link:first-child{
        display: block;
        border: none;
        padding: 0;
        margin: 0 0 10px;
    }
    .title_wrapper .sub_title{
        font-size: 24px;
        line-height: 24px;
    }
}
@media screen and (max-width:$medium_phone) {
    .MuiModal-root .modal_block .modal_content .primary_btn .btn{
        width: 100%;
        margin: 10px 0 0;
    }
    .login .login_inner .left_part.part .leftpart_inner .logo img {
        width: 200px;
        height: 58px;
    }
    .main_content .main_block .top_bar .right_part .right_part_inner{
        flex-direction: column;
    }
    .main_content .main_block .top_bar .right_part .right_part_inner .add_kit {
        margin: 15px 0 0;
    }
    .primary_btn .btn{
        width: 100%;
        margin-top: 10px;
    }
    .primary_btn .btn:last-child{
        margin-left: 0;
    }
    .login.sign_up .login_inner .left_part .leftpart_inner .part.bottom_part .item_wrapper .item{
        width: 50%;
    }
    .cms_page .title_wrapper .sub_title{
        font-size: 26px;
        line-height: 26px;
    }

}
@media screen and (max-width:$small_phone) {
    .login .login_inner .right_part .rightpart_inner .login_form_inner form .login_title {
        margin: 0 0 20px;
        padding: 0 0 15px;
        font-size: 26px;
        line-height: 30px;
    }
    .back_link {
        margin-top: 20px;
    }
    .main_content .main_block{
        margin: 20px 0;
    }
    .breadcum_wrapper .breadcrumb_title {
        font-size: 24px;
        line-height: 24px;
    }
    .breadcum_wrapper .breadcrumb li{
        font-size: 14px;
        line-height: 18px;
    }
    .login.login_acc .login_inner .left_part .leftpart_inner .part.middle_part .item_wrapper .item{
        width: 50%;
    }
}