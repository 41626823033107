@import '../../assets/css/variable/variable';

.privacy_policy{
    background-color: $white_color;
    .breadcum_wrapper{
        background-image: url(../../assets/img/breadcum_privacy.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
}