@import '../../../src/assets/css/variable/variable';
.prelogin_header{
    background-color: $white_color;
    padding: 20px 0;    
    .navbar {
        padding: 0;
            .logo{
                img{
                    width: 153px;
                    height: 45px;
                }
            }
            .navbar-collapse{
                .navbar-nav{
                    li{
                        margin: 0 15px;
                        .nav-link {
                            font-weight: 500;
                            font-size: $font_ex_large;
                            line-height: 24px;
                            color: $bodycolor;
                            &:hover,&:focus{
                                color: $bodysecondarycolor; 
                            }
                            &.active{
                                color: $bodysecondarycolor;
                                cursor: default;
                            }
                        }
                    }
                }
            }
            .signup_link{
                color: $bodysecondarycolor;
                text-transform: uppercase;
                text-decoration: underline;
                font-weight: 400;
                font-size: $font_medium;
                margin: 0 0 0  15px;
                &:hover{
                    color: $bodycolor; 
                }
            }
       
    }
    &.sticky{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
        background-color: $white_color;
        animation: 0.8s ease 0s normal both 1 running fixedAnim;
		-webkit-animation: 0.8s ease 0s normal both 1 running fixedAnim;
		-moz-animation: 0.8s ease 0s normal both 1 running fixedAnim;
		-ms-animation: 0.8s ease 0s normal both 1 running fixedAnim;
		-o-animation: 0.8s ease 0s normal both 1 running fixedAnim;
    }
    @-webkit-keyframes fixedAnim{0%{top:-100%;}100%{top:0;}}
	@-moz-keyframes fixedAnim{0%{top:-100%;}100%{top:0;}}
	@keyframes fixedAnim{0%{top:-100%;}100%{top:0;}}
}


@media screen and (min-width:$desktop_pro_up) {
    .prelogin_header .navbar .container .navbar-collapse{
       justify-content: flex-end;
    }
}
@media screen and (max-width:$tablet) {
    .prelogin_header .navbar .container{
        width: 100%;
    }
    .prelogin_header .navbar .navbar-toggler{
        min-width: auto;
        padding: 0;
        border: none;
    }
    .prelogin_header .navbar .navbar-toggler:focus{
        box-shadow: none;
    }
    .prelogin_header .navbar .navbar-toggler{
        order:1;
    }
    .prelogin_header .navbar .logo{
        order: 2;
        flex-grow: 1;
        margin: 0 15px;
    }
    .prelogin_header .navbar .signup_link{
        order:3;
    }
    .prelogin_header .navbar .navbar-collapse{
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin: 15px 0;
        background: #ffffff;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        z-index: 9;
    }
    .prelogin_header .navbar .navbar-collapse .navbar-nav li .nav-link{
        font-size: 16px;
    }
}
@media screen and (max-width:$medium_phone) {
    .prelogin_header .navbar .logo img {
        width: 140px;
        height: 36px;
    }
    .prelogin_header .navbar .logo {
        margin: 0 8px;
    }
}